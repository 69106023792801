
import { Component, Vue } from 'vue-property-decorator';
import MBPAppForm from '@/components/mbp-app/MBPAppForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { getMBPAppTemplate } from '@/utility/mbp-app';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
    Anchor,
    MBPAppForm,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    ValidationErrorNotification,
  },
})
export default class MBPAppAdd extends mixins(PendingChangesGuard) {
  value: MBPApp | null = null;
  initialValue: MBPApp | null = null;

  private created() {
    this.initValue();
  }

  private initValue() {
    const mbpAppTemplate = getMBPAppTemplate();
    mbpAppTemplate.id = uuid();
    this.initialValue = cloneDeep(mbpAppTemplate);
    this.value = cloneDeep(mbpAppTemplate);
  }

  public revert() {
    this.initValue();
  }

  public save() {
    this.$store
      .dispatch('MBPApp/create', this.value)
      .then(createdValue => {
        this.initialValue = createdValue;
        this.value = createdValue;
        this.$toast.success(this.$t('mbp-app.create.created'));
        this.$router.push({ name: 'mbp-app.edit', params: { id: createdValue.id } });
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('mbp-app.create.error'));
      });
  }
}
